import { AutocompleteInput, ReferenceInput } from "react-admin"
import {
  useOrganizationId,
  useOrganizationalUnitId,
} from "../../hooks/useOrganizationId"
import { useEnabledModules } from "../hooks/useEnabledModules"

export function OrganizationalUnitInput(props) {
  const [organizational_unit_id] = useOrganizationalUnitId()
  const { areOrganizationalUnitsEnabled, isLoading } = useEnabledModules()
  const [organization_id] = useOrganizationId()
  if (!areOrganizationalUnitsEnabled || isLoading) return null
  const filter: {
    organization_id: string
    "deleted_at@is": string
    organizational_unit_id?: string
  } = {
    organization_id,
    "deleted_at@is": "NULL",
  }
  if (organizational_unit_id !== undefined) {
    filter.organizational_unit_id = organizational_unit_id
  }

  return (
    <ReferenceInput {...props} reference="organizational_units" filter={filter}>
      <AutocompleteInput
        source="name"
        optionText="name"
        filterToQuery={(query) => ({ "name@ilike": query })}
      />
    </ReferenceInput>
  )
}
